import React from 'react';
import {useState} from "react";
import {useParams, useSearchParams, useNavigate} from 'react-router-dom';

function Payment() {
    // eslint-disable-next-line no-undef
    const checkout = YooMoneyCheckout(217752, {
        language: "ru"
    });

    const params = useParams();
    const [searchParams, setSearchParams] = useState();

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();



        checkout.tokenize({
            number: document.querySelector('.number').value,
            cvc: document.querySelector('.cvc').value,
            month: document.querySelector('.expiry_month').value,
            year: document.querySelector('.expiry_year').value
        }).then(res => {
            if (res.status === 'success') {
                const {paymentToken} = res.data.response;
                let user_data = {
                    user_id: params.user_id,
                    token: paymentToken
                };

                fetch(
                    `https://vasyacameron.ru/api/payment/`, {
                        method: 'POST',
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-type": "application/json;charset=UTF-8"
                        },
                        body: JSON.stringify(user_data)
                    }
                ).then(resp => resp.text()).then(console.log);

                //navigate("/");
                return paymentToken;
            } else {
                handleIncorrectInput();
            }
        });


    };

    return (
        <main className="main">
            <div className="payment_wrapper">
                <h2 className="message_correct" id="iii">Некорректный ввод</h2>
                <form action="/" onSubmit={handleSubmit} className="payment_form" method="POST">
                    <label htmlFor="number">Номер карты</label>
                    <input type="text" className='number' name="number"/>
                    <label htmlFor="cvc">CVC</label>
                    <input type="text" className='cvc' name="cvc"/>

                    <div className="payment_date">
                        <label htmlFor="expiry_month">Месяц</label>
                        <input type="text" className='expiry_month' name="expiry_month"/>

                        <label htmlFor="expiry_year">Год</label>
                        <input type="text" className='expiry_year' name="expiry_year"/>
                    </div>

                    <button className="btn" type="submit" id="pay_btn">
                        Оплатить 79 рублей за жалобу
                    </button>
                </form>
            </div>
        </main>
    )

    function handleIncorrectInput() {
        let mess = document.getElementById('iii');
        console.log(mess);
        mess.classList.add("show");
    };


    function tokenize_func() {
        checkout.tokenize({
            number: document.querySelector('.number').value,
            cvc: document.querySelector('.cvc').value,
            month: document.querySelector('.expiry_month').value,
            year: document.querySelector('.expiry_year').value
        }).then(res => {
            if (res.status === 'success') {
                const {paymentToken} = res.data.response;

                return paymentToken;
            } else {

                return "error";
            }
        });
    }

};


export default Payment;
