import React from 'react';

const Warning = () => {
    return (
        <main className="main">
            <h1 className="page-title">Предложения и жалобы</h1>
            <p>Все предложение и жалобы вы можете направить на электронную почту -
                <a className="feedback-link" href="mailto:Vasyacameron@rambler.ru"> Vasyacameron@rambler.ru</a>
            </p>

        </main>
    )
}

export default Warning;