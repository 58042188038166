import React from 'react';

const Footer = () =>{
    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <div className="footer__text">
                    <p className="footer__text_p">
                        Проект реализуется при поддержке
                        Федерального государственного бюджетного учреждение «Фонд содействия развитию малых
                        форм предприятий в научно-технической сфере» (Фонд содействия инновациям)
                    </p>
                </div>
                <div className="footer__image">
                    <img className="fond-image" src="/fond-logo_c.jpg" alt="fond_logo" />
                </div>
            </div>
            <p className="feedback">Обратная связь: <a className="feedback-link"
                                                       href="mailto:Vasyacameron@rambler.ru">Vasyacameron@rambler.ru</a>
            </p>
        </footer>
    )
}

export default Footer;