import React from 'react';
import ym from "react-yandex-metrika";

const Navigation = () => {
    return (
        <div className="navigation-container">
            <nav className="navigation">
                <ul className="navigation-list">
                    <li>
                        <a
                            className="item__link"
                            href="/"
                            target=""
                            onClick={() => ym(93701096, 'reachGoal', 'quest-click-me')}
                            rel="noopener noreferrer"><img src="/logo_new.jpg" alt="" width={40}/></a>
                    </li>
                    <li className="navigation-list__item">Новости</li>

                    <li className="navigation-list__item">
                        <a
                            className="item__link"
                            href="/offers"
                            target=""
                            onClick={() => ym(93701096, 'reachGoal', 'quest-click-me')}
                            rel="noopener noreferrer">Предложения и жалобы</a>
                    </li>

                    <li className="navigation-list__item">
                        <a
                            className="item__link"
                            href="/about"
                            target=""
                            onClick={() => ym(93701096, 'reachGoal', 'quest-click-me')}
                            rel="noopener noreferrer">О проекте</a>
                    </li>

                    <li className="navigation-list__item">
                        <a
                            className="item__link"
                            href="/questions_answers"
                            target=""
                            onClick={() => ym(93701096, 'reachGoal', 'quest-click-me')}
                            rel="noopener noreferrer">Вопрос-ответ</a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navigation;