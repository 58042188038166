import React from 'react';
import ym from "react-yandex-metrika";

const Header = () => {
    return (
        <header className="header">
            <div className="header__wrapper">
                <div className="header__wrapper-image__container">
                    <a href="/" onClick={()=> ym(93701096,'reachGoal','main_page')}>
                        <img className="header__wrapper-image" src="/logo_new.jpg" alt="logo_new"/>
                    </a>

                </div>
                <div className="header__wrapper-text">
                    <h1 className="header_wrapper-h1">Вася Cameron</h1>
                    <p className="header__wrapper-description">
                        Бот формирует документ для обжалования штрафов, вынесенных на основании камер видеофиксации
                    </p>
                </div>
            </div>
        </header>
    )
}

export default Header;