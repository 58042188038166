import React from 'react';
import ym from "react-yandex-metrika";

const About = () => {
    return (
        <main className="main">
            <h1 className="page-title">О проекте</h1>
            <p>
                Реализуется LegalTech продукт - интеллектуальная информационная система, которая
                позволяет создать жалобу на постановление о привлечении к административной
                ответственности за нарушения ПДД, зафиксированных автоматическими камерами
                фиксации нарушений.
            </p>
            <p>
                В сервисе разработаны технологии по роботизированному оказанию правовых
                консультаций, составлению документов и их отправке. Юридический сервис позволяет
                без участия юриста решать вопросы, связанные с обжалованием автомобильных штрафов.
                Бот способен составить жалобу для оспаривания следующих ошибочных ситуаций:
            </p>
            <p><b>1.</b> Парковка на инвалидном месте:</p>
            <p><b>1.1.</b> Камера не распознала знак «инвалид» на ТС;</p>
            <p><b>1.2.</b> Знак «Инвалидное место» был демонтирован, но камера зафиксировала нарушение;</p>
            <p><b>2.</b> Выезд на встречную полосу:</p>
            <p><b>2.1.</b> Камера зафиксировала объезд ДТП как нарушение;</p>
            <p><b>2.2.</b> Камера зафиксировала объезд препятствия как нарушение;</p>
            <p><b>3.</b> Штраф за скорость, не предусмотренную техническими характеристиками ТС;</p>
            <p><b>4.</b> Ошибочное привлечение к ответственности не того субъекта;</p>
            <p><b>5.</b> Камера ошиблась в распознавании номера транспортного средства;</p>
            <p><b>6.</b> Камера ошибочно зафиксировала нарушение правил применения ремней
                безопасности и мотошлема;</p>
            <p><b>7.</b> Камера зафиксировала использование мобильного телефона при управлении
                транспортным средством (по телефону говорил не водитель);</p>
            <p><b>8.</b> Нарушение правил остановки транспортного средства (поломка, пропуск
                специального транспортного средства);</p>
            <p><b>9.</b> Выделенные полосы (ненадлежащая организация движения);</p>
            <p><b>10.</b> Водителю транспортного средства не было видно дорожной разметки;</p>
            <p><b>11.</b> Камера распознала тень автомобиля и зафиксировала нарушение;</p>
            <p><b>12.</b> Ошибка при назначении штрафа за среднюю скорость;</p>
            <p><b>13.</b> Двойная фикция одного нарушения;</p>
            <p><b>14.</b> Расхождение знака и настроек дорожных камер;</p>
            <p><b>15.</b> Штраф за нарушение автомобиля двойника;</p>
            <p><b>16.</b> Штраф за автомобиль, находящийся на эвакуаторе;</p>
            <p><b>17.</b> Неправильная установка знака;</p>
            <p><b>18.</b> Ошибка камеры при фиксации остановки транспортного средства на стоп-линии.</p>

        </main>
    )
}

export default About;