import React from 'react';

const Questions = () => {
    return (
        <div className='main'>
            <h2 className="page-title">
                Вопрос-ответ
            </h2>
            <div className="question__container">
                <div className="question__text">
                    <h3>Кто несет ответственность за нарушение ПДД зафиксированное камерами видеонаблюдения?</h3>
                    <p className="question__answer">К ответственности привлекают собственников (владельцев) этих
                        автомобилей.</p>

                    <h3 className="question__header">Кто составляет протокол об административном правонарушении
                        при фиксации нарушений камерами дорожного движения?
                    </h3>
                    <p className="question__answer">Протокол не составляется, а постановление по делу об
                        административном правонарушени
                        и выносится без участия лица, в отношении которого возбуждено дело об административном
                        правонарушении.
                    </p>
                </div>
                <div>
                    <img className="question_img" src="/5565.jpg" alt="iii"/>
                </div>
            </div>

            <div className="question__container">
                <div className="question__text">
                    <h3 className="question__header">
                        Кто выносит постановления о привлечении к административной ответственности за нарушение ПДД?
                    </h3>
                    <p className="question__answer">
                        Центр автоматической фиксации административных правонарушений в области дорожного
                        движения ГИБДД (ч. 1 ст. 2.6.1, п. 5 ч. 2 ст. 23.3, ч. 3 ст. 28.6, ч. 1 ст. 29.9 КоАП РФ).
                    </p>
                </div>
                <div>
                    <img className="car_img" src="/qt12_dg4i_210629.jpg" alt="eee"/>
                </div>
            </div>

            <h2 className="attention">ВАЖНО!</h2>
            <p className="attention-text">
                Собственник (владелец) автомобиля освобождается от ответственности, если в момент фиксации
                правонарушения
                транспортное средство находилось во владении или в пользовании другого. (ч. 2 ст. 2.6.1 КоАП РФ).
            </p>

            <div className="question__container">
                <div className="question__text">
                    <h3 className="question__header">
                        Как оспорить штраф?
                    </h3>
                    <p className="question__answer">
                        Для того, что бы оспорить нарушение ПДД, зафиксированное
                        камерами видеонаблюдения сформируйте жалобу с помощью бота.
                        Сформированная жалоба может быть подана в орган, должностному лицу, которыми вынесено
                        постановление.
                        Сформированная жалоба также может быть подана в форме электронного документа с использованием
                        Единого портала
                        государственных и муниципальных услуг (Госуслуг).
                        Помните, что срок подачи жалобы - 10 суток со дня вручения или получения копии постановления.
                    </p>
                </div>
                <div>
                    <img className="traffic_light_img" src="/traffic-light.png" alt="nnn"/>
                </div>
            </div>

            <h3 className="res">
                По результатам рассмотрения жалобы выносится одно из следующих решений (ч. 1 ст. 30.7 КоАП РФ):
            </h3>

            <ul>
                <li>
                    об оставлении постановления без изменения, а жалобы без удовлетворения;
                </li>
                <li>
                    изменении постановления, если при этом не усиливается административное наказание
                    или иным образом не ухудшается положение лица, в отношении которого вынесено постановление;
                </li>
                <li>
                    отмене постановления и о прекращении производства по делу;
                </li>
                <li>
                    отмене постановления и о возвращении дела на новое рассмотрение судье, в орган,
                    должностному лицу, правомочным рассмотреть дело;
                </li>
                <li>отмене постановления и о направлении дела на рассмотрение по
                    подведомственности, если постановление было вынесено неправомочными судьей, органом, должностным
                    лицом.
                </li>
            </ul>

            <h3 className="res">Какие нарушения фиксируют дорожные камеры?</h3>
            <ol>
                <li>Нарушения скоростного режима;</li>
                <li>Нарушения правил остановки и стоянки;</li>
                <li>Нарушения правил проезда регулируемых перекрестков (проезд на запрещающий сигнал светофора, выезд за
                    стоп-линию при запрещающем сигнале светофора);
                </li>
                <li>Нарушения правил остановки/стоянки;</li>
                <li>Нарушения горизонтальной разметки - выезд на полосу встречного движения;</li>
                <li>Нарушения правил применения ремней безопасности водителем и пассажирами;</li>
                <li>Нарушения правил пользования телефоном лицом, управляющим транспортным средством.</li>
            </ol>

            <h3 className="res">Ситуации</h3>
            <p>Одна из ситуаций, когда специальное техническое средство допустило ошибку фиксации нарушений ПДД.
                Камера ошибочно фиксирует нарушения горизонтальной разметки - выезд на полосу встречного движения.
                Грузовой автомобиль без регистрационного знака транспортного средства перекрыл полосу движения, в
                результате водители, движущиеся по перекрытой полосе, вынуждены выезжать на встречную полосу.
            </p>
            <div className="imgs">
                <p className="img_p">
                    <img className="img__item" src="/photo_2023-11-02_22-22-34.jpg" alt=""/>
                </p>
                <p className="img_p">
                    <img className="img__item" src="/photo_2023-11-02_22-22-34%20(2).jpg" alt=""/>
                </p>
            </div>
        </div>

    )
}

export default Questions;

