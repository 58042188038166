import './App.css';
import {Helmet} from "react-helmet";
import Header from "./compotents/Header";
import Footer from "./compotents/Footer";
import Navigation from "./compotents/Navigation";
import Main from "./compotents/Main";
import Questions from "./compotents/Questions";
import Payment from "./compotents/Payment";
import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ym, {YMInitializer} from 'react-yandex-metrika';
import About from "./compotents/AboutProject";
import Warning from "./compotents/Warnings";

function App() {
    return (
    <BrowserRouter>
        <div className="App">
            <YMInitializer accounts={[93701096]} options={{ defer: true }} />
            <Head/>
            <Navigation/>

            <Routes>
                <Route path='/' element={<Main/>} onClick={()=> ym(93701096,'reachGoal','main_page')} />
                <Route path='/questions_answers' element={<Questions/>} onClick={()=> ym(93701096,'reachGoal','quest-click-me')} />
                <Route path='/about' element={<About/>} onClick={()=> ym(93701096,'reachGoal','quest-click-me')} />
                <Route path='/offers' element={<Warning/>} onClick={()=> ym(93701096,'reachGoal','quest-click-me')} />
                <Route path='/payment/:user_id' element={<Payment/>} />
            </Routes>


            <div className="waves">
                <img className="test_wave" src="/Frame4.png" alt="footer_wave"/>
            </div>
            <Footer/>
        </div>
    </BrowserRouter>
    );
}

const Head = () => {
    return (
        <div>
            <script src='../public/scripts/main.js'></script>
            <Helmet
                title="Вася Cameron"
                meta={
                    [
                        {
                            "name": "description",
                            "content": "Вася Cameron - телеграм бот, предназначенный для обжалования штрафов ГИБДД",
                        },
                        {
                            "property": "og:title", "content": "Вася Cameron - снимаю штрафы"
                        },
                        {
                            "property": "og:description", "content": "Вася Cameron - телеграм бот," +
                                " предназначенный для обжалования штрафов ГИБДД"
                        },
                        {
                            "property": "og:image", "content": "/logo.jpg"
                        }
                    ]
                }


            />
        </div>
    )
}


export default App;