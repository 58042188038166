import React from 'react';
import Header from "./Header";

const Main = () =>{
    return(
        <div>
            <Header/>
            <div className="waves">
                <img className="test_wave" src="/Frame5.png" alt="header_wave"/>
            </div>
            <main className="main">

            <div className="video__wrapper">
                <iframe className="video_frame" src="https://www.youtube.com/embed/n8vEmssuLq0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>

            <h2 className="tg-header">Ссылки на телеграм бота</h2>

            <div className="telegram__wrapper">
                <div className="qr-code">
                    <img className="qr-image" src="/qr.png" alt="qr" />
                </div>
                <div className="tg-link__wrapper">
                    <p>
                        <a className="tg-link" href="https://t.me/AppFine_Bot">https://t.me/AppFine_Bot</a>
                    </p>
                </div>
            </div>

        </main>
        </div>


    )
}

export default Main;